import React, { useEffect } from 'react';

import Aos from 'aos';
import 'aos/dist/aos.css';
import { Link, useLocation } from 'react-router-dom';
import Marquee from 'react-fast-marquee';
import CountUp, { useCountUp } from 'react-countup';

const Home = () => {
  useCountUp({
    ref: 'counter',
    end: 1234567,
    enableScrollSpy: true,
    scrollSpyDelay: 2000,
  });

  useEffect(function () {
    Aos.init({ disable: 'mobile' });
  }, []);

  const location = useLocation();

  // useEffect(() => {
  // window.scrollTo(0, 0);
  // }, [location]);

  return (
    <>
      {/* Banner */}
      <div className="bg-grad">
        <section className="banner" id="home">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 mb-md-0 mb-4 text-center">
                <h1
                  className="mb-0"
                  data-aos="fade-up"
                  data-aos-duration="1100"
                >
                  Blast <span className="danger-text">Native</span> DEX
                  Aggregator for <br className="d-lg-block d-none" /> Optimal
                  Order Routing
                </h1>
                <p
                  className="my-3 py-md-2"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  Delivering ultimate savings to users.
                </p>
                <img
                  src="assets/blast.png"
                  className="blast"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  alt=""
                />
                <div
                  className="d-flex flex-md-row flex-column justify-content-center gap-3 mt-md-5 mt-4"
                  data-aos="fade-up"
                  data-aos-duration="2500"
                >
                  <a
                    href="https://app.thruster.finance/?token2=0x75483179a38d21F3608E71bbEdE5EC1314F0067D"
                    target={'_blank'}
                    className="prime-btn"
                    rel="noopener noreferrer"
                  >
                   <img src="assets/thru.png" alt="" />
                  </a>
                  <a
                    href="https://www.mexc.com/exchange/NPTX_USDT"
                    target={'_blank'}
                    className="prime-btn"
                    rel="noopener noreferrer"
                  >
                   <img src="assets/mex.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="powered pt-5 mt-lg-5 d-md-block d-none">
          <div className="container">
            <div
              className="row align-items-center"
              data-aos="fade-up"
              data-aos-duration="2700"
            >
              <div className="col-md-12">
                <h3 className="mb-4">Featured in</h3>
              </div>
              <div className="col-md-2 col-6 px-lg-4 mb-md-0 mb-3"><img src="assets/fin.png" className='w-100 h-auto' alt="" /></div>
              <div className="col-md-2 col-6 px-lg-4 mb-md-0 mb-3"><img src="assets/ai.png" className='w-100 h-auto' alt="" /></div>
              <div className="col-md-2 col-6 px-lg-4 mb-md-0 mb-3"><img src="assets/crypto.png" className='w-100 h-auto' alt="" /></div>
              <div className="col-md-2 col-6 px-lg-4 mb-md-0 mb-3"><img src="assets/gn.png" className='w-100 h-auto' alt="" /></div>
              <div className="col-md-2 col-6 px-lg-4 mb-md-0"><img src="assets/ben.png" className='w-100 h-auto' alt="" /></div>
              <div className="col-md-2 col-6 px-lg-4 mb-md-0"><img src="assets/mw.png" className='w-100 h-auto' alt="" /></div>
            </div>
          </div>
        </section>

        <section className="powered pt-5 mt-lg-5 d-block d-md-none">
          <div className="container">
            <div
              className="row align-items-center"
              data-aos="fade-up"
              data-aos-duration="2700"
            >
              <div className="col-md-12">
                <h3 className="mb-4">Featured in</h3>
              </div>
              <Marquee>
              <div className='mx-2'><img src="assets/fin.png"style={{height: "30px"}} alt="" /></div>
              <div className='mx-2'><img src="assets/ai.png"style={{height: "30px"}} alt="" /></div>
              <div className='mx-2'><img src="assets/crypto.png"style={{height: "80px"}} alt="" /></div>
              <div className='mx-2'><img src="assets/gn.png"style={{height: "45px"}} alt="" /></div>
              <div className='mx-2'><img src="assets/ben.png"style={{height: "40px"}} alt="" /></div>
              <div className='mx-2'><img src="assets/mw.png"style={{height: "50px"}} alt="" /></div>
              </Marquee>
            </div>
          </div>
        </section>

        <section className="bg-2">
          <div className="container">
            <div className="row" data-aos="fade-right" data-aos-duration="1000">
              <div className="col-lg-10 col-md-11 mb-lg-4">
                <h2 className="mb-lg-5 mb-4">
                  A{' '}
                  <span className="danger-text"> Smart Routing Algorithm </span>
                  for Optimal Order Flow
                </h2>
              </div>
            </div>
            <div className="row" data-aos="fade-up" data-aos-duration="1500">
              <div className="col-lg-3 col-md-6 mb-4">
                <h3>
                  <CountUp end={118} enableScrollSpy />M
                  <span className="danger-text">+</span>
                </h3>
                <p>Daily Trading Volume*</p>
              </div>

              <div className="col-lg-3 col-md-6 mb-4">
                <h3>
                  <CountUp end={40} enableScrollSpy />k
                  <span className="danger-text">+</span>
                </h3>
                <p>Daily Trades*</p>
              </div>

              <div className="col-lg-3 col-md-6 mb-4">
                <h3>
                  <CountUp end={200} enableScrollSpy />
                  <span className="danger-text">+</span>
                </h3>
                <p>Trading Pairs*</p>
              </div>

              <div className="col-lg-3 col-md-6 mb-4 nine">
                <h3>
                  <CountUp end={9} enableScrollSpy />
                </h3>
                <p>DEXs*</p>
              </div>
            </div>
            <p
              className="danger-text text-md-end fs-18 fw-600"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              *Total Blast Market Size
            </p>
          </div>
        </section>
        <section className="powered pb-5">
          <div className="container">
            <div
              className="row align-items-center"
              data-aos="fade-up"
              data-aos-duration="2700"
            >
              <div className="col-md-12">
                <h3 className="mb-md-5 mb-4">Powered by</h3>
              </div>
              <Marquee>
                <img src="assets/pw1.svg" className="mx-4" alt="" />
                <img src="assets/pw2.svg" className="mx-4" alt="" />
                <img src="assets/pw3.svg" className="mx-4" alt="" />
                <img src="assets/pw4.svg" className="mx-4" alt="" />
                <img src="assets/pw5.svg" className="mx-4" alt="" />
              </Marquee>
            </div>
          </div>
        </section>

        <section className="" id="feature">
          <div className="container">
            <div className="bg-3" data-aos="fade-up" data-aos-duration="1500">
              <div className="inner-bg">
                <div className="row align-items-end">
                  <div className="col-md-12">
                    <h3>
                      The Ultimate Path for your{' '}
                      <span className="danger-text">Swaps</span>
                    </h3>
                    <p>
                      NeptuneX Smart Order Routing (SOR) utilizes a unique
                      proprietary algorithm to search significantly more complex
                      (non-linear) paths through an unlimited set of connector
                      tokens.
                    </p>
                  </div>
                  <div
                    className="col-md-7 mb-lg-0 mb-4"
                    data-aos="fade-left"
                    data-aos-duration="2000"
                  >
                    <h3 className="mt-lg-4">
                      Best Prices <span className="danger-text">In DeFi</span>
                    </h3>
                    <p>
                    Access NeptuneX's core service to swap cryptos at the best rates across all 
                    major DEXs on Blast. Our smart contract optimization ensures these paths can 
                    be executed without compromising on gas costs. As a result, NeptuneX is able 
                    to find the ultimate path for any swap, delivering significant savings to its users.
                    </p>
                    <h3 className="mt-lg-4">
                      Permissionless{' '}
                      <span className="danger-text">Trading</span>
                    </h3>
                    <p>
                      Decentralized exchanges are built on smart contracts and
                      allow for permissionless listing and swapping of tokens,
                      providing unlimited access to onchain assets. Unlike
                      centralized exchanges, users maintain full control over
                      their funds when swapping on NeptuneX, eliminating
                      third-party custody risks.
                    </p>
                  </div>
                  <div
                    className="col-md-5"
                    data-aos="fade-right"
                    data-aos-duration="2000"
                  >
                    <img src="assets/swap.png" className="w-100" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="partners">
          <div
            className="container"
            data-aos="zoom-in"
            data-aos-duration="1500"
          >
            <h3>Integrated Liquidity Sources</h3>
            <Marquee className="pt-md-4 pt-3">
              <div className="position-relative pb-3">
                <img src="assets/p1.svg" className="mx-2 mx-md-3" alt="" />
              </div>
              <div className="position-relative pb-3">
                <img src="assets/p2.svg" className="mx-2 mx-md-3" alt="" />
              </div>
              <div className="position-relative pb-3">
                <img src="assets/p3.svg" className="mx-2 mx-md-3" alt="" />
                {/* <div className="soon">Soon</div> */}
              </div>
              <div className="position-relative pb-3">
                <img src="assets/p4.svg" className="mx-2 mx-md-3" alt="" />
                <div className="soon">Soon</div>
              </div>
              <div className="position-relative pb-3">
                <img src="assets/p5.svg" className="mx-2 mx-md-3" alt="" />
                {/* <div className="soon">Soon</div> */}
              </div>
              <div className="position-relative pb-3">
                <img src="assets/p6.svg" className="mx-2 mx-md-3" alt="" />
                <div className="soon">Soon</div>
              </div>
              <div className="position-relative pb-3">
                <img src="assets/p7.svg" className="mx-2 mx-md-3" alt="" />
                <div className="soon">Soon</div>
              </div>
              <div className="position-relative pb-3">
                <img src="assets/p8.svg" className="mx-2 mx-md-3" alt="" />
                <div className="soon">Soon</div>
              </div>
              <div className="position-relative pb-3">
                <img src="assets/p9.svg" className="mx-2 mx-md-3" alt="" />
                <div className="soon">Soon</div>
              </div>
            </Marquee>
          </div>
        </section>

        <section className="pt-lg-5" id="campaign">
          <div className="container pt-5">
            <div className="row justify-content-center pb-4">
              <div
                className="col-lg-8 mb-4 text-center"
                data-aos="fade-up"
                data-aos-duration="1800"
              >
                <h2 className="mb-4">
                  Join NeptuneX{' '}
                  <span className="danger-text">Points Campaign</span>
                </h2>
                <p className="mb-4">
                Be among the first users and start trying out NeptuneX on the Blast Mainnet. 
                Join our Points Campaign today.
                </p>
                <a
                  href="https://medium.com/@NeptuneX_io/introducing-neptunex-points-program-c07a040d3316"
                  target={'_blank'}
                  className="primary-btn"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="partners" id='partner'>
          <div
            className="container"
            data-aos="zoom-in"
            data-aos-duration="1500"
          >
            <h3>Our Partners</h3>
            <Marquee className="pt-md-4 pt-3">
              <div className="position-relative pb-3">
                <img src="assets/prt1.svg" className="mx-2 mx-md-3" alt="" />
              </div>
              <div className="position-relative pb-3">
                <img src="assets/prt2.svg" className="mx-2 mx-md-3" alt="" />
              </div>
              <div className="position-relative pb-3">
                <img src="assets/audit.svg" className="mx-2 mx-md-3" alt="" />
              </div>
              <div className="position-relative pb-3">
                <img src="assets/prt4.svg" className="mx-2 mx-md-3" alt="" />
              </div>
              <div className="position-relative pb-3">
                <img src="assets/prt5.svg" className="mx-2 mx-md-3" alt="" />
              </div>
              <div className="position-relative pb-3">
                <img src="assets/prt6.png" height={70} className="mx-2 mx-md-3" alt="" />
              </div>
            </Marquee>
          </div>
        </section>

        <section className="featured">
          <div className="container">
            <div
              className="mb-4 pb-2"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <h3 className="mb-0">Featured News</h3>
            </div>
            <div className="row">
              <div
                className="col-lg-4 col-md-6 mb-lg-0 mb-4"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <a
                  href="https://medium.com/@NeptuneX_io/introducing-neptunex-points-program-c07a040d3316"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="feature-card"
                >
                  <div className="feature-img">
                    <img src="assets/f1.png" className="w-100" alt="" />
                  </div>
                  <div className="feature-detail">
                    <p>
                    Introducing NeptuneX Points Program
                    </p>
                    <p className="danger-text">Apr 23, 2024</p>
                  </div>
                </a>
              </div>

              <div
                className="col-lg-4 col-md-6 mb-lg-0 mb-4"
                data-aos="fade-up"
                data-aos-duration="1300"
              >
                <a
                  href="https://medium.com/@NeptuneX_io/announcing-the-neptunex-fair-launch-and-tokenomics-66a957a5fef4"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="feature-card"
                >
                  <div className="feature-img">
                    <img src="assets/f2.png" className="w-100" alt="" />
                  </div>
                  <div className="feature-detail">
                    <p>Announcing the NeptuneX Fair Launch</p>
                    <p className="danger-text">Apr 16, 2024</p>
                  </div>
                </a>
              </div>

              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-duration="1600"
              >
                <a
                  href="https://medium.com/@NeptuneX_io/introducing-neptunex-scroll-native-dex-aggregator-for-optimal-order-routing-e577a9a4f4b3"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="feature-card"
                >
                  <div className="feature-img">
                    <img src="assets/f3.png" className="w-100" alt="" />
                  </div>
                  <div className="feature-detail">
                    <p>
                      Introducing NeptuneX: Blast Native DEX Aggregator for
                      Optimal Order Routing
                    </p>
                    <p className="danger-text">Feb 28, 2024</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="article" id="articles">
          <div className="container">
            <div
              className="mb-4 pb-2"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <h3 className="mb-0">Latest Articles</h3>
            </div>
            <div className="row">
              <div
                className="col-lg-4 col-md-6 mb-lg-0 mb-4"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <a
                  href="https://medium.com/@NeptuneX_io/explanation-of-the-smart-routing-algorithm-for-optimal-order-flow-af7dde10fd1e"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="feature-card"
                >
                  <div className="feature-img">
                    <img src="assets/a1.png" className="w-100" alt="" />
                  </div>
                  <div className="feature-detail">
                    <p>
                      Explanation of the Smart Routing Algorithm for Optimal
                      Order Flow
                    </p>
                    <p className="danger-text">Feb 29, 2024</p>
                  </div>
                </a>
              </div>

              <div
                className="col-lg-4 col-md-6 mb-lg-0 mb-4"
                data-aos="fade-up"
                data-aos-duration="1600"
              >
                <a
                  href="https://medium.com/@NeptuneX_io/our-roadmap-and-vision-to-launch-on-scroll-mainnet-837e72d77250"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="feature-card"
                >
                  <div className="feature-img">
                    <img src="assets/a2.png" className="w-100" alt="" />
                  </div>
                  <div className="feature-detail">
                    <p>Our Roadmap and Vision to Launch on Blast Mainnet</p>
                    <p className="danger-text">Feb 29, 2024</p>
                  </div>
                </a>
              </div>

              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <a
                  href="https://medium.com/@NeptuneX_io/introducing-neptunex-scroll-native-dex-aggregator-for-optimal-order-routing-e577a9a4f4b3"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="feature-card"
                >
                  <div className="feature-img">
                    <img src="assets/a3.png" className="w-100" alt="" />
                  </div>
                  <div className="feature-detail">
                    <p>Choosing Blast as a Developer friendly ecosystem</p>
                    <p className="danger-text">Feb 28, 2024</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className='audit'>
            <div className="row justify-content-center">
              <div className="col-md-5 col-10">
              <div className="d-flex align-items-center gap-4">
              <h3 className="mb-0">Audited by</h3>
              <a href="https://github.com/solidproof/projects/blob/main/2024/NeptuneX/SmartContract_Audit_Solidproof_NeptuneX.pdf" target="_blank" rel="noopener noreferrer">
                <img src="assets/audit.svg" className='w-100' alt="" /></a></div></div>
            </div>
            </div>
          </div>
        </section>

        <section className="community">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div
                  className="com-card"
                  data-aos="fade-up"
                  data-aos-duration="1300"
                >
                  <div className="row align-items-center">
                    <div className="col-md-4 mb-md-0 mb-3 text-center">
                      <a href="http://docs.NeptuneX.io" target="_blank">
                        <img src="assets/c1.svg" alt="" />
                      </a>
                    </div>
                    <div className="col-md-4 mb-md-0 mb-3 text-center">
                      <a
                        href="https://discord.gg/NeptuneX"
                        target="_blank"
                        className="active"
                      >
                        <img src="assets/c2.svg" alt="" />
                      </a>
                    </div>
                    <div className="col-md-4 text-center">
                      <a href="https://discord.gg/NeptuneX" target="_blank">
                        <img src="assets/c3.svg" alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home;
