import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

const Header = () => {
  const [stickyClass, setStickyClass] = useState("relative");
  const homeNav = () => {
    if (document.body.classList.contains("home-sm")) {
      document.body.classList.remove("home-sm");
    } else {
      document.body.classList.add("home-sm");
    }
  };

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 50 ? setStickyClass("bg-pink") : setStickyClass("");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  return (
    <div>
      <nav
        className={`navbar navbar-expand-lg flex-column transition-all pt-0 pb-lg-0 ${stickyClass}`}
      >
        <div className="top-banner">
          <div className="container">
            Join NeptuneX Points Program and Receive Airdrop —{" "}
            <a
              href="https://medium.com/@NeptuneX_io/introducing-neptunex-points-program-c07a040d3316"
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </a>{" "}
          </div>
        </div>
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src="assets/logo.png" className="light-logo" alt="" />
          </Link>
          <div className="d-lg-none d-inline-flex gap-2 align-items-center">
            {/* <a href="javascript:void(0)" target={"_blank"} rel="noreferrer" className="primary-btn d-lg-none d-flex h-auto px-3 fs-14">
          Launch App
              </a> */}
            <button
              className="navbar-toggler"
              type="button"
              onClick={() => {
                homeNav();
              }}
            >
              <img src="assets/menu.svg" className="" alt="" />
            </button>
          </div>

          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarSupportedContent"
          >
            <div className="d-lg-none d-flex justify-content-between text-end p-4">
              <Link to="/" className="navbar-brand">
                <img src="assets/logo-dark.svg" alt="" />
              </Link>
              <a
                href="javascript:void(0)"
                onClick={() => {
                  homeNav();
                }}
                className="cross-btn"
              >
                <span className="iconify" data-icon="akar-icons:cross"></span>
              </a>
            </div>
            <ul className="navbar-nav align-items-center me-auto px-4 pe-lg-5">
              <li className="nav-item">
                <a
                  href="#home"
                  className="nav-link"
                  onClick={() => {
                    homeNav();
                  }}
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#feature"
                  className="nav-link"
                  onClick={() => {
                    homeNav();
                  }}
                >
                  Features
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#campaign"
                  className="nav-link"
                  onClick={() => {
                    homeNav();
                  }}
                >
                  Campaigns
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#articles"
                  className="nav-link"
                  onClick={() => {
                    homeNav();
                  }}
                >
                  Articles
                </a>
              </li>
            </ul>
            <div className="d-flex pt-lg-0 pt-5 px-4 px-md-0">
              <a
                href="https://swap.neptunex.io"
                target={"_blank"}
                className="primary-btn gap-1"
                rel="noreferrer"
              >
                Launch App
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
