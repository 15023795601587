import React from "react";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container py-3">
          <div className="row py-lg-5 pb-4 border-bottom border-dark">
            <div className="col-md-3 col-6 mb-md-6 mb-lg-0 mb-4">
              <h4>Developers</h4>
              <ul>
                <li>
                  <a
                    href="http://docs.NeptuneX.io"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Documentation{" "}
                  </a>{" "}
                </li>
                <li>
                  <a
                    href="https://github.com/NeptuneX-Aggregator"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    GitHub{" "}
                  </a>{" "}
                </li>
                <li>
                  <a
                    href="http://docs.NeptuneX.io"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Audit{" "}
                  </a>{" "}
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-6 mb-md-6 mb-lg-0 mb-4">
              <h4>Community</h4>
              <ul>
                <li>
                  <a
                    href="https://twitter.com/NeptuneX_io_"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Twitter{" "}
                  </a>{" "}
                </li>
                <li>
                  <a
                    href="https://discord.gg/NeptuneX"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Discord{" "}
                  </a>{" "}
                </li>
                <li>
                  <a
                    href="http://docs.NeptuneX.io"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Branding/Media Kit{" "}
                  </a>{" "}
                </li>
                <li>
                  <a
                    href="https://zealy.io/cw/neptunex"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Zealy{" "}
                  </a>{" "}
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-6 mb-md-6">
              <h4>Application</h4>
              <ul>
                <li>
                  <a
                    href="https://swap.neptunex.io"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Web App{" "}
                  </a>{" "}
                </li>
                <li>
                  <a
                    href="https://discord.gg/NeptuneX"
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Submit Feedback{" "}
                  </a>{" "}
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-6 mb-md-6">
              <h4>Legal</h4>
              <ul>
                <li>
                  <a
                    href="https://docs.neptunex.io/protocol/terms-of-use"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Terms of Service{" "}
                  </a>{" "}
                </li>
                <li>
                  <a
                    href="https://docs.neptunex.io/protocol/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Privacy Policy{" "}
                  </a>{" "}
                </li>
              </ul>
            </div>
          </div>
          <div className="row align-items-center pt-4 pt-lg-5">
            <div className="col-md-6 mb-md-0 mb-4">
              <div className="d-flex flex-md-row flex-column align-items-md-center gap-4">
                <span>
                  <img src="assets/logo.png" className="light-logo" alt="" />
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-md-row flex-column justify-content-md-end justify-content-start align-items-md-center gap-4">
                <div className="d-flex align-items-center gap-4">
                  <a
                    href="https://github.com/NeptuneX-Aggregator"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-ic"
                  >
                    <span>
                      <img src="assets/git.svg" alt="" />
                    </span>
                  </a>
                  <a
                    href="https://medium.com/@NeptuneX_io"
                    target={"_blank"}
                    rel="noopener noreferrer"
                    className="footer-ic"
                  >
                    <span>
                      <img src="assets/medium.svg" alt="" />
                    </span>
                  </a>
                  <a
                    href="https://twitter.com/NeptuneX_io_"
                    target={"_blank"}
                    rel="noopener noreferrer"
                    className="footer-ic"
                  >
                    <span>
                      <img src="assets/x.svg" alt="" />
                    </span>
                  </a>
                  <a
                    href="https://discord.gg/NeptuneX"
                    target={"_blank"}
                    rel="noopener noreferrer"
                    className="footer-ic"
                  >
                    <span>
                      <img src="assets/discord.svg" alt="" />
                    </span>
                  </a>
                </div>
                <div>
                  <a
                    href="http://docs.NeptuneX.io"
                    target={"_blank"}
                    rel="noopener noreferrer"
                    className="primary-btn px-5"
                  >
                    Docs
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-12 pt-4">
              <div className="d-flex flex-wrap align-items-start  align-items-md-center sub-footer">
                <p className="mb-0">Copyright 2024 NeptuneX</p>
                <p className="mb-0">All rights reserved</p>
                <a
                  href="http://docs.NeptuneX.io"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link"
                >
                  Terms of Service{" "}
                </a>
                <a
                  href="http://docs.NeptuneX.io"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-link"
                >
                  Privacy Policy{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
